import { CONSTANTS } from "@@addons/constants";
import { LoginStore } from "@@addons/database/dixie/login";
import { http } from "@@addons/functions/https/http";
import { LoginStore_I } from "@@addons/interfaces/database/login";

export async function GET_AkwaabaClientUser() {
  const appStore = new LoginStore();
  let __memberId = 0,
    __userProfile: LoginStore_I = {};
  await appStore.getId(async (res) => {
    __memberId = res;
  });
  await appStore.getOne(__memberId, async (response) => {
    __userProfile = response;
  });
  const memberId = __userProfile.id,
    rememberMe = __userProfile.rememberMe;

  const url = CONSTANTS.URLS.AKWAABA_API_BASE_URL + 'clients/users/' + memberId,
    HTTP = await http(url, {
      method: "GET",
    }, true, false);

  let output;
  try {
    output = HTTP;
    if (HTTP.success === true) {
      const DATAS: any = [HTTP.data];
      let newDatas: LoginStore_I[] = [];

      DATAS.forEach(async (rawData: any) => {
        let formatRawData: LoginStore_I = appStore.formatRawData(rawData);
        // console.log({ formatRawData });
        formatRawData.rememberMe = rememberMe;

        if (!newDatas.includes(formatRawData)) {
          newDatas.push(formatRawData);
        }
      });
      // console.log({ DATAS, "ds": newDatas });
      
      await appStore.makePost(newDatas, (postResponse) => {
        // console.log({ postResponse });
      });
    }
  } catch (error) {
    output = error;
    console.error({ error });
  }

  return output;
}