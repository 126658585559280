import "../../../assets/styles/views/homepage/main.scss";
import '../../../pages/wireframe';
import "@@addons/widgets/alert";
import '@material/mwc-circular-progress';
import { MobxLitElement } from '@adobe/lit-mobx';
import { html, css, TemplateResult, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { wireframeProvider } from '@@addons/mobx-providers/wireframe';
import { AppMessenger } from "@@addons/classes/messenger_channel";
import { GET_ClientDashboardReport } from "@@addons/network/dashboard/client-report";
import { AppDashboardReport_I } from "@@addons/interfaces/dashboard";
import { AppDashboardReportStore } from "@@addons/database/dixie/dashboard";
import { GetUserPageFx } from "@@addons/functions/views/wireframe/get_user_page";
import { LoginStore_I } from "@@addons/interfaces/database/login";
import { loginProfileProvider } from "@@addons/mobx-providers/login";
import { GetDbLoadedAllFx } from "@@addons/functions/views/wireframe/db_loaded/all";
import "@@addons/widgets/alert/clickable";
import { CONSTANTS } from "@@addons/constants";

@customElement("akwaaba-forms-homepage")
export class AkwaabaFormsHomepage extends MobxLitElement {
  constructor() { super(); }

  @property({ type: Number })
  public pageId: number = 0;

  private _userProfile: LoginStore_I = {};

  public set userProfile(value: LoginStore_I) {
    this._userProfile = value;
  }

  public get userProfile(): LoginStore_I {
    return this._userProfile;
  }

  @property({ type: Object })
  private _dashboardReportResponse: any;

  @property({ type: Array })
  private _dashboardReports?: AppDashboardReport_I[] = [];

  @property({ type: Number })
  private _clientId: number = 0;

  @property({ type: Number })
  private _clientUserId: number = 0;

  @property({ type: Array })
  list = [...Array(15).keys()];

  connectedCallback() {
    super.connectedCallback();

    setInterval(async () => {
      GetUserPageFx(
        this.userProfile,
        loginProfileProvider,
        (userProfile) => {
          this.userProfile = userProfile;
          this._clientId = userProfile.accountId;
          this._clientUserId = userProfile.id;
          // console.log({ "~~~~~~~~~~this.userProfile": this.userProfile });
          this.getClientDashboardReport().then(async res => {
            // console.log({ res });
            this._dashboardReportResponse = res;
            this._dashboardReports = [];
          });
        },
      );
      await this.dashboardReports();
    }, 1000);

    wireframeProvider.setPageId(this.pageId);

    const aMr = new AppMessenger<boolean>('request-reload?');
    aMr.listen(async (reload) => {
      // console.log({ "aM-reload": reload });
      if (reload) {

        await this.refreshPage();
        this.requestUpdate();
      }
    });
  }

  disconnectedCallback() { }
  static styles = [
    css`
   :host { display: block; }
  `
  ];

  render() {
    // console.log({ "this.list": this.list });
    return html`
      <akwaaba-wireframe></akwaaba-wireframe>
      <div slot="wireframecontent" class="main-app-content" scrollbar="app-scrollbar">
        ${this.getDisplay}
      </div>
      
    `;
  }

  private get getDisplay() {
    if (this._dashboardReports === null) {
      return html`
        <div class="main-container">
          <alert-card info .header="${html`Empty`}" .content="${html`No Data Found`}"></alert-card>
        </div>
      `;
    } else {
      if (this._dashboardReports.length > 0) {
        let networkRes: TemplateResult<1> | symbol = nothing;
        if (this._dashboardReportResponse !== undefined) {
          if (this._dashboardReportResponse['success']) {
            this.dashboardReports();
          } else {
            networkRes = html`
              <alert-card warning .header="${html`Warning`}" .content="${html`${this._dashboardReportResponse['msg']}`}"></alert-card>
            `;
          }
        }
        return html`
          ${networkRes}
          
          <div class="app-container !px-0 ">
            <div class="app-container-row flex justify-start !px-0">
              ${this._dashboardReports.map(report => {
                return html`
                  <div class="app-container-col-md-6">
                    <clickable-card warning description="${report.description}" 
                      icon="${report.icon}" title="${report.title}" 
                      url="${CONSTANTS.URLS.HOME_PAGE_BASE_URL}${report.url}"></clickable-card>
                  </div>
                `
              })}
            </div>
          </div>
        `;
      } else {
        return html`
          <div class="main-container">
            <div class="flex justify-center">
              <mwc-circular-progress indeterminate></mwc-circular-progress>
            </div>
          </div>
        `;
      }
    }
  }

  firstUpdated() { }

  private async dashboardReports() {
    // console.log({"this._dashboardReports-before": this._dashboardReports});

    this._dashboardReports = await GetDbLoadedAllFx<AppDashboardReport_I>(
      AppDashboardReportStore, this._dashboardReports);
    // console.log({"this._dashboardReports-after": this._dashboardReports});
  }

  private async getClientDashboardReport() {
    return await GET_ClientDashboardReport(
      this.userProfile,
    );
  }

  private async refreshPage(): Promise<void> {
    await this.getClientDashboardReport().then(async res => {
      // console.log({ res });
      this._dashboardReportResponse = res;
      this._dashboardReports = [];
      await this.dashboardReports();
    });
    return;
  }

  createRenderRoot() {
    return this;
  }
}
