import { CONSTANTS } from "@@addons/constants";
import { AppMenuStore } from "@@addons/database/dixie/app_menus";
import { http } from "@@addons/functions/https/http";
import { AppMenus_I } from "@@addons/interfaces/database/app_menu";
import { LoginProfileProvider } from "@@addons/mobx-providers/login";
import { toJS } from "mobx";

export async function GET_UserMenus(loginProfileProvider: LoginProfileProvider) {
  const appMenuStore = new AppMenuStore();
  // console.log({ "loginProfileProvider.profile": toJS(loginProfileProvider.profile) });
  const profile = toJS(loginProfileProvider.profile),
    clientId: number = profile.accountId, clientUserId: number = profile.id;
  const url = CONSTANTS.URLS.AKWAABA_API_BASE_URL + 'modules/users?clientId=' + clientId + '&clientUserId=' + clientUserId,
    HTTP = await http(url,{
      method: "GET",
    }, true,);
  try {
    if (HTTP.success === true) {
      const DATAS: any = HTTP.data;
      let newDatas: AppMenus_I[] = [];

      DATAS.forEach(async (rawData: any) => {
        let formatRawData = appMenuStore.formatRawData(rawData);
        // console.log({ formatRawData });
        if (!newDatas.includes(formatRawData)) {
          newDatas.push(formatRawData);
        }
      });
      // console.log({ newDatas, "ds": newDatas });
      appMenuStore.makePost(newDatas, (postResponse) => {
        // console.log({ postResponse });
      });
    }
  } catch (error) {
    console.error({ error });
  }
}